import {
  MouseParallaxChild,
  MouseParallaxContainer,
} from "react-parallax-mouse";
import Image from "./Image";

const Cover = ({ src, track, count = 1, children }) => {
  const sx = {
    transform: "scale(1.1)",
    height: "100%",
    width: "100%",
    position: "absolute",
  };

  const f = 0.3;

  const parallax = ({ asset }) => {
    return (
      <div className="cover">
        <MouseParallaxContainer
          className="parallax"
          useWindowMouseEvents
          containerStyle={{
            ...sx,
            width: "100%",
            // display: "grid",
            // position: "absolute",
            gridTemplateColumns: "auto auto auto auto auto",
          }}
          globalFactorX={-0.3}
          globalFactorY={-0.3}
          resetOnLeave
        >
          <MouseParallaxChild
            key={`cover-backup`}
            factorX={0.5 * f}
            factorY={0.5 * f}
            style={{
              ...sx,
            }}
          >
            <Image src={"cover-sm.jpg"} alt="" sx={sx} />
          </MouseParallaxChild>

          {Array.from({ length: count }, (_, i) => (
            <MouseParallaxChild
              key={`cover${i}`}
              factorX={0.5 * f * (i + 1)}
              factorY={0.5 * f * (i + 1)}
              style={{
                ...sx,
                opacity: (count - i) / count, // Calculate opacity based on index i
              }}
            >
              <Image src={asset} alt="" sx={sx} />
            </MouseParallaxChild>
          ))}
        </MouseParallaxContainer>
      </div>
    );
  };

  const getAssetFromTrack = (track) => track.covers ?? src;
  return parallax({ asset: getAssetFromTrack(track) });
};

export default Cover;
