import { useState } from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Stack,
  Box,
} from "@mui/material";
import "react-lazy-load-image-component/src/effects/blur.css";

import KonamiCode from "./components/KonamiCode";
import moment from "moment";

import Cover from "./components/Cover";
import tracks from "./data/tracks";
import Player from "./Player";
import Waveform from "./components/Waveform";

function App() {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const isSelected = (item) => selectedIndex === item;
  var track = tracks.find((e) => e.id === selectedIndex);
  const [analyser, setAnalyser] = useState(null);
  const [showWaveform, setShowWaveform] = useState(true);

  const onLast = () => {
    if (selectedIndex - 1 < 0) {
      setSelectedIndex(tracks.length - 1);
    } else {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  const onNext = () => {
    if (selectedIndex < tracks.length - 1) {
      setSelectedIndex(selectedIndex + 1);
    } else {
      setSelectedIndex(0);
    }
  };

  const TrackList = () => {
    return (
      tracks.length > 0 && (
        <List className="list" component="nav">
          {tracks.map((item, i) => {
            return (
              <ListItem
                key={"item_" + i}
                button
                selected={isSelected(i)}
                onClick={() => {
                  setSelectedIndex(i);
                  setIsPlaying(true);
                }}
              >
                <ListItemText
                  align="left"
                  primary={
                    <Stack direction="row" spacing={2}>
                      <Typography type="body2">{i + 1}.</Typography>
                      <Typography type="body2">{item.name}</Typography>
                    </Stack>
                  }
                />
                <ListItemText
                  align="right"
                  secondary={moment.utc(item.duration * 1000).format("mm:ss")}
                />
              </ListItem>
            );
          })}
        </List>
      )
    );
  };

  return (
    <>
      <KonamiCode handler={() => setIsUnlocked((v) => !v)} />

      <Cover
        count={1}
        track={isPlaying ? track : false}
        src={isUnlocked ? "assets/images/loading.webp" : "cover.webp"}
      />
      {showWaveform && <Waveform analyser={analyser} />}
      <Box justifyContent="center" alignItems="center">
        <Player
          setIsPlaying={(play) => {
            if (!selectedIndex) setSelectedIndex(0);
            setIsPlaying(play);
          }}
          {...{
            isPlaying,
            onNext,
            onLast,
            tracks,
            selectedIndex,
            analyser,
            setAnalyser,
            showWaveform,
            setShowWaveform,
          }}
        >
          <TrackList />
        </Player>
      </Box>
    </>
  );
}

export default App;
