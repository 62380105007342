import { Stack, Typography } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import Scrollbar from "./Scrollbar";

const sx = {
  px: 2,
  whiteSpace: "pre-line",
  textAlign: "start",
  color: "rgba(255, 255, 255, 0.8)",
  fontWeight: "bold",
};

export default function Lyrics({ isPlaying, time, track }) {
  const [currentLineIndex, setCurrentLineIndex] = useState(0);

  useEffect(() => {
    const newIndex = track.lyrics.findIndex(
      (line, lineIndex) =>
        time >= line.time && time < track.lyrics[lineIndex + 1]?.time
    );
    setCurrentLineIndex(newIndex);
  }, [time, track.lyrics]);

  const previousLyrics =
    currentLineIndex > 0 ? track.lyrics.slice(0, currentLineIndex) : [];
  const currentLyrics = track.lyrics[currentLineIndex] || null;
  const nextLyrics =
    currentLineIndex < track.lyrics.length - 1
      ? track.lyrics.slice(currentLineIndex + 1)
      : [];

  const logsEndRef = useRef(null);

  const scrollToBottom = () =>
    logsEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });

  useEffect(() => {
    scrollToBottom();
  }, [currentLyrics]);

  const enableBlur = false;
  return (
    <Scrollbar sx={{ maxHeight: 300, minWidth: 300, overflow: "auto" }}>
      <Stack spacing={2}>
        {previousLyrics.map((lyric, i) => (
          <Typography
            key={`pre-${i}`}
            variant="body"
            sx={{
              ...sx,
              filter: `blur(${isPlaying && enableBlur ? "1.5px" : "0"})`,
            }}
          >
            {lyric.text}
          </Typography>
        ))}
        <div ref={logsEndRef}>
          {currentLyrics && (
            <Typography key={`current`} variant="h5" sx={sx}>
              {currentLyrics.text}
            </Typography>
          )}
        </div>
        {nextLyrics.map((lyric, i) => (
          <Typography
            key={`next-${i}`}
            variant="body"
            sx={{
              ...sx,
              filter: `blur(${isPlaying && enableBlur ? "1.5px" : "0"})`,
            }}
          >
            {lyric.text}
          </Typography>
        ))}
      </Stack>
    </Scrollbar>
  );
}
