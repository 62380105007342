const tracks = [
  {
    id: 0,
    name: "Feed the Machine",
    album: "Just Say When",
    artist: "Parmesan Dreams",
    duration: 184,
    path: "assets/audio/1_feed_the_machine.mp3",

    lyrics: [
      { time: 24, text: "Gears grind" },
      { time: 27, text: "Hard drives" },
      { time: 29, text: "Farming data to sell shit to you" },
      { time: 35, text: "Numbers" },
      { time: 37, text: "Multiplied" },
      { time: 40, text: "Algorithms that destroy your life" },
      { time: 47, text: "Feed them machine" },
      { time: 50, text: "Feed them machine" },
      { time: 53, text: "Feed them machine" },
      { time: 59, text: "Feed them machine" },
      { time: 62, text: "Feed them machine" },
      { time: 65, text: "Feed them machine" },
      { time: 92, text: "A.I." },
      { time: 95, text: "By some white guys" },
      { time: 98, text: "In a board room with no thought for you" },
      { time: 105, text: "Panopticon" },
      { time: 106, text: "Observation" },
      { time: 108, text: "Prison guard always staring at you" },
      { time: 116, text: "Feed them machine" },
      { time: 119, text: "Feed them machine" },
      { time: 122, text: "Feed them machine" },
      { time: 128, text: "Feed them machine" },
      { time: 131, text: "Feed them machine" },
      { time: 133, text: "Feed them machine" },
      { time: 137, text: "" },
    ],
  },
  {
    id: 1,
    name: "Dressed in Blue",
    album: "Just Say When",
    artist: "Parmesan Dreams",
    duration: 214,
    path: "assets/audio/2_dressed_in_blue.mp3",
    lyrics: [
      { time: 23, text: "Take me to the place you wanna go" },
      { time: 30, text: "I can see it there though far away" },
      { time: 39, text: "Every day that passes we closer" },
      { time: 47, text: "I can see it now though far away" },
      { time: 54, text: "In the morning I’ll be fine" },
      { time: 58, text: "It keeps passing all the time" },
      { time: 61, text: "What am I to do" },
      { time: 63, text: "Standing dressed in blue" },
      { time: 65, text: "Staring back at you" },
      { time: 78, text: "Wake me when the flowers are in bloom" },
      { time: 85, text: "When the seasons pass and start anew" },
      { time: 93, text: "Tell me all the things that you are thinking" },
      { time: 100, text: "I can see that look upon your face" },
      { time: 109, text: "In the morning you’ll be fine" },
      { time: 113, text: "It keeps passing all the time" },
      { time: 115, text: "What are you to do" },
      { time: 118, text: "Standing dressed in blue" },
      { time: 120, text: "Staring back at you" },
      { time: 148, text: "In the morning I’ll be fine" },
      { time: 152, text: "It keeps passing all the time" },
      { time: 155, text: "What am I to do" },
      { time: 157, text: "Standing dressed in blue" },
      { time: 159, text: "Staring back at you" },
      { time: 164, text: "In the morning you’ll be fine" },
      { time: 168, text: "It keeps passing all the time" },
      { time: 171, text: "What are you to do" },
      { time: 173, text: "Standing dressed in blue" },
      { time: 175, text: "Staring back at you" },
      { time: 179, text: "In the morning I’ll be fine" },
      { time: 184, text: "In the morning I’ll be fine" },
      { time: 187, text: "What am I to do" },
      { time: 188, text: "Standing dressed in blue" },
      { time: 190, text: "Staring back at you" },
      { time: 194, text: "What am I to do" },
      { time: 196, text: "Standing dressed in blue" },
      { time: 198, text: "Staring back at you" },
      { time: 202, text: "What am I to do" },
      { time: 204, text: "Standing dressed in blue" },
      { time: 206, text: "Staring back at you" },
      { time: 210, text: "" },
    ],
  },
  {
    id: 2,
    name: "Make U My Home",
    album: "Just Say When",
    artist: "Parmesan Dreams",
    duration: 176,
    path: "assets/audio/3_make_u_my_home.mp3",
    lyrics: [
      { time: 0.5, text: "Around the corner" },
      { time: 2, text: "I see a let down coming my way" },
      { time: 7, text: "Following the break of day" },
      { time: 18, text: "Up in my lighthouse" },
      { time: 20, text: "Guiding my ships back home" },
      { time: 24, text: "Sorry, couldn’t pickup the phone" },
      { time: 35, text: "I should’ve known" },
      { time: 36, text: "You were alone" },
      { time: 38, text: "And I was the first one you’d call" },
      { time: 41, text: "When you’re down on your own" },
      { time: 45, text: "Down off your throne" },
      { time: 47, text: "To throw you a bone" },
      { time: 49, text: "To take you back home" },
      { time: 51, text: "Just to make you my home" },
      { time: 53, text: "Make you my home" },
      { time: 55, text: "Make you my home" },
      { time: 58, text: "Make you my home" },
      { time: 69, text: "Around the corner" },
      { time: 70, text: "I see a storm cloud rolling my way" },
      { time: 75, text: "Following the break of day" },
      { time: 85, text: "Out on the ocean" },
      { time: 88, text: "Guided by lights back home" },
      { time: 92, text: "Wish you would've answered phone" },
      { time: 102, text: "I should’ve known" },
      { time: 104, text: "You were alone" },
      { time: 106, text: "And I was the first one you’d call" },
      { time: 108, text: "When you’re down on your own" },
      { time: 112, text: "Down off your throne" },
      { time: 114, text: "To throw you a bone" },
      { time: 116, text: "To take you back home" },
      { time: 118, text: "Just to make you my home" },
      { time: 121, text: "Make you my home" },
      { time: 123, text: "Make you my home" },
      { time: 125, text: "Make you my home" },
      { time: 130, text: "" },
    ],
  },
  {
    id: 3,
    name: "I Don’t Wanna Stay at This Party Anymore",
    album: "Just Say When",
    artist: "Parmesan Dreams",
    duration: 195,
    path: "assets/audio/4_i_dont_wanna_stay_at_this_party_anymore.mp3",
    lyrics: [
      { time: 12, text: "I don’t wanna stay at this party anymore" },
      {
        time: 22,
        text: "It’s hard, to be honest, trying not to figure my way out",
      },
      { time: 32, text: "Give me the possibility I might surprise myself you" },
      {
        time: 42,
        text: "Take me back to my apartment where I can feel unwell",
      },
      { time: 52, text: "I tried my best" },
      { time: 54, text: "But I can’t find another" },
      { time: 56, text: "Reason to stay" },
      { time: 59, text: "So I’ll walk away" },
      { time: 61, text: "It’s getting late" },
      { time: 63, text: "I’m not feeling great" },
      { time: 65, text: "I gotta get going I guess" },
      { time: 69, text: "‘Cause my mind is a mess" },
      { time: 71, text: "Another night well spent" },
      { time: 89, text: "I don’t feel like keeping this up anymore" },
      {
        time: 99,
        text: "The smalltalk pains me, and I am fading from this all",
      },
      {
        time: 109,
        text: "Give me the possibility I’d like to do this again soon",
      },
      {
        time: 119,
        text: "Take me back to my folks house where I can feel unwell",
      },
      { time: 128, text: "I tried my best" },
      { time: 130, text: "But I can’t find another" },
      { time: 133, text: "Reason to stay" },
      { time: 136, text: "So I’ll walk away" },
      { time: 138, text: "It’s getting late" },
      { time: 140, text: "I’m not feeling great" },
      { time: 142, text: "I gotta get going I guess" },
      { time: 145, text: "‘Cause my mind is a mess" },
      { time: 148, text: "Another night well spent" },
      { time: 155, text: "" },
    ],
  },
  {
    id: 4,
    name: "Something to Rely On",
    album: "Just Say When",
    artist: "Parmesan Dreams",
    duration: 247,
    path: "assets/audio/5_something_to_rely_on.mp3",
    lyrics: [
      { time: 24, text: "Cut me open" },
      { time: 26, text: "Doctor give a cure" },
      { time: 33, text: "Spilling over" },
      { time: 36, text: "Not feeling how I should" },
      { time: 42, text: "‘Cause I need" },
      { time: 45, text: "Something" },
      { time: 47, text: "To rely on" },
      { time: 53, text: "Please give me" },
      { time: 56, text: "Something" },
      { time: 58, text: "I can" },
      { time: 86, text: "Feeling funny" },
      { time: 89, text: "I’ve been seeing shapes" },
      { time: 97, text: "Roll me over" },
      { time: 99, text: "Give me a good shake" },
      { time: 105, text: "‘Cause I need" },
      { time: 108, text: "Something" },
      { time: 110, text: "To rely on" },
      { time: 116, text: "Please give me" },
      { time: 118, text: "Something" },
      { time: 121, text: "I can" },
      { time: 169, text: "Something to rely on" },
      { time: 180, text: "Something to rely on" },
      { time: 190, text: "‘Cause I need" },
      { time: 193, text: "Something" },
      { time: 195, text: "To rely on" },
      { time: 200, text: "Please give me" },
      { time: 302, text: "Something" },
      { time: 205, text: "I can" },
      { time: 210, text: "" },
    ],
  },
];

export default tracks;
