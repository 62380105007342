import React, { useState, useEffect, useRef } from "react";

const Waveform = ({ analyser, children }) => {
  const canvasRef = useRef(null);
  const animationFrameRef = useRef(null);

  useEffect(() => {
    if (!analyser) {
      console.info("Analyser not provided to the Waveform component.");
      return;
    }

    const canvas = canvasRef.current;
    const canvasCtx = canvas.getContext("2d");

    const bufferLength = analyser.fftSize;
    const dataArray = new Uint8Array(bufferLength);

    const draw = () => {
      animationFrameRef.current = requestAnimationFrame(draw);

      // Copy the current waveform data into the dataArray
      analyser.getByteTimeDomainData(dataArray);

      // Clear the canvas
      canvasCtx.clearRect(0, 0, canvas.width, canvas.height);

      // Set up the waveform visualization
      canvasCtx.lineWidth = 2;
      canvasCtx.strokeStyle = "#f06ee6";
      canvasCtx.shadowColor = "#fbcbf9"; // Set the glow effect color
      canvasCtx.shadowBlur = 10; // Set the glow effect blur size
      canvasCtx.beginPath();

      const sliceWidth = (canvas.width * 1.0) / bufferLength;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] / 128.0;
        const y = (v * canvas.height) / 2;

        if (i === 0) {
          canvasCtx.moveTo(x, y);
        } else {
          canvasCtx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      // Draw the waveform on the canvas
      canvasCtx.lineTo(canvas.width, canvas.height / 2);
      canvasCtx.stroke();
    };

    // Start drawing the waveform
    draw();

    // Clean up the animation frame
    return () => {
      cancelAnimationFrame(animationFrameRef.current);
    };
  }, [analyser]);

  // Function to handle the number of pages in the PDF
  const refContainer = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  useEffect(() => {
    if (refContainer.current) {
      setDimensions({
        width: refContainer.current.offsetWidth,
        height: refContainer.current.offsetHeight,
      });
    }
  }, []);

  return (
    <div
      ref={refContainer}
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <canvas
        ref={canvasRef}
        width={dimensions.width}
        height={dimensions.width}
        style={{
          zIndex: -1,
          position: "absolute",
          top: "25%",
          height: "50%",
          width: "100%",
        }}
      />
    </div>
  );
};

export default Waveform;
