import { useState } from "react";
import { Box, Grid, Card, CardActionArea } from "@mui/material";

import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";

import ScrollBar from "./Scrollbar";
import Image from "./Image";

const config = {
  images: [
    {
      title: "Just Say When",
      description:
        "Taken from bed on an early summer evening from my apartment.",
      src: "assets/images/cheese-dreams.jpg",
    },
    {
      title: "A Big Mess",
      description:
        "Try as I might, the space usually devolves into a cluster of cables and gear.",
      src: "assets/images/bigmess.jpg",
    },
    {
      title: "Mix Check",
      description: "Louis and I listen back after tweaking some mixes.",
      src: "assets/images/mix-check.jpg",
    },
    {
      title: "Sun Spot",
      description: "Lou takes a nap in his cabana under the sun.",
      src: "assets/images/louInStu.jpg",
    },
    {
      title: "Vibe check",
      description:
        "Lou likes to hang out on the little yellow couch, we try to keep it vibey.",
      src: "assets/images/louInStu2.jpg",
    },
    {
      title: "Writing Session",
      description: "Working out Dressed in Blue on the wurli.",
      src: "assets/images/wurliwriting.webp",
    },
    {
      title: "Flea Market Find",
      description:
        "I found an omnichord at our local flea market, so of course had to buy it!",
      src: "assets/images/omnichord.jpg",
    },
    {
      title: "Another Flea Find",
      description:
        "Another flea find! $5 for this FM keyboard which made its way all over the record.",
      src: "assets/images/fleakeys.webp",
    },
    {
      title: "Studio 1/2",
      description:
        "My little garage studio space I setup at my parents after moving back home.",
      src: "assets/images/studio.jpg",
    },
    {
      title: "Studio 2/2",
      description:
        "The wall of keys, the drums, and mixing desk, amongst various other audio gear and garage belongings, including the little fireplace which kept the space just warm enough to work through the winter.",
      src: "assets/images/studio2.jpg",
    },
    {
      title: "Walks in the Woods",
      description:
        "I try to get out and go for walks in the woods in between recording / mixing.",
      src: "assets/images/walk.jpg",
    },
    {
      title: "Window",
      description: "A window at dusk which felt rather pensive.",
      src: "assets/images/window.jpg",
    },
    {
      title: "Scope Check",
      description: "A peek on the scope, for good measure.",
      src: "assets/images/scope.webp",
    },
    {
      title: "Pretty Lights",
      description: "Some pretty lights from a night out.",
      src: "assets/images/prettylights.webp",
    },
    {
      title: "Andrew's Notes 1/2",
      description:
        "After sending Andrew some demos/rough mixes he kindly wrote up some notes and provided excellent feedback, and some reassurance on elements I was unsure of.",
      src: "assets/images/notes-1.jpg",
    },
    {
      title: "Andrew's Notes 2/2",
      description:
        "We'd go back and forth on ideas, discussing different directions which the songs could go in.",
      src: "assets/images/notes-2.jpg",
    },
    {
      title: "Feed the Machine",
      description:
        "Gears grind, hard drives, farming data to sell shit to you.",
      src: "assets/images/feedthemachine.webp",
    },
    {
      title: "Dressed in Blue",
      description: "Take me to the place you want to go.",
      src: "assets/images/dressedinblue.webp",
    },
    {
      title: "Make u my home",
      description: "Up in my lighthouse, guiding my ships back home.",
      src: "assets/images/makeumyhome.webp",
    },
    {
      title: "I don't wanna stay at this party anymore",
      description: "Another night well spent.",
      src: "assets/images/idontwannastayatthisparty.webp",
    },
    {
      title: "Something to Rely On",
      description: "Cut me open, doctor give a cure.",
      src: "assets/images/somethingtorelyon.webp",
    },
  ],
};

export default function Gallery() {
  const [open, setOpen] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);

  const onImageClick = (index) => {
    setOpen(true);
    setCurrentIndex(index);
  };

  return (
    <>
      <ScrollBar>
        <Box sx={{ p: 2, maxHeight: "300px" }}>
          <Grid container direction="row" spacing={1}>
            {config.images.map((e, index) => (
              <GridItemImage
                image={e}
                key={index}
                size={12 / (config.rows ?? 3)}
                {...{ index, onImageClick }}
              />
            ))}
          </Grid>
        </Box>
      </ScrollBar>
      <Lightbox
        plugins={[Captions]}
        open={open}
        index={currentIndex}
        close={() => setOpen(false)}
        slides={config.images}
      />
    </>
  );
}

const GridItemImage = ({ image, index, onImageClick, size }) => {
  return (
    <Grid item xs={size}>
      <Card sx={{ borderRadius: "4%" }}>
        <CardActionArea
          onClick={() => {
            onImageClick(index);
          }}
          className="card-image-container"
        >
          <div className="img">
            <Image
              src={image.src}
              contentMode="fill"
              ratio="1/1"
              className="wrapper"
            />
          </div>
        </CardActionArea>
      </Card>
    </Grid>
  );
};
