import { useEffect, useState, useRef } from "react";
import { styled, useTheme } from "@mui/material/styles";

import {
  Box,
  Typography,
  Slider,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";

import {
  PauseRounded,
  PlayArrowRounded,
  FastForwardRounded,
  FastRewindRounded,
  VolumeUpRounded,
  VolumeDownRounded,
  Lyrics,
  Wallpaper,
  QueueMusicRounded,
  AutoAwesomeRounded,
} from "@mui/icons-material";

import LyricsView from "./components/Lyrics";
import Image from "./components/Image";
import Credits from "./components/Credits";
import Gallery from "./components/Gallery";

const Widget = styled("div")(({ theme }) => ({
  top: 20,
  padding: 16,
  borderRadius: 16,
  minWidth: 100,
  maxWidth: 320,
  margin: "auto",
  position: "relative",
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(0,0,0,0.6)" : "rgba(255,255,255,0.4)",
  backdropFilter: "blur(40px)",
}));

const CoverImage = styled("div")({
  width: 100,
  height: 100,
  objectFit: "cover",
  overflow: "hidden",
  flexShrink: 0,
  borderRadius: 8,
  backgroundColor: "rgba(0,0,0,0.08)",
  "& > img": {
    width: "100%",
  },
});

const TinyText = styled(Typography)({
  fontSize: "0.75rem",
  opacity: 0.38,
  fontWeight: 500,
  letterSpacing: 0.2,
});

export default function MusicPlayerSlider({
  selectedIndex,
  tracks,
  children,
  isPlaying,
  setIsPlaying,
  onNext,
  onLast,
  setAnalyser,
  analyser,
  showWaveform,
  setShowWaveform,
}) {
  const track = tracks[selectedIndex ?? 0];
  const { name, album, artist, path } = track;

  const theme = useTheme();
  let audio = new Audio(track.path);

  const audioRef = useRef(audio);
  const audioContextRef = useRef(null);

  const intervalRef = useRef();
  const isReady = useRef(false);

  const duration = audioRef.current.duration || 0;
  const currentTime = audioRef.current.currentTime || 0;

  const [volume, setVolume] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [trackProgress, setTrackProgress] = useState(0);
  const [tabValue, setTabValue] = useState("tracks");

  const handleTabChange = (newValue) => setTabValue(newValue);
  const isTabSelected = (value) => value === tabValue;

  // Effect to handle audio playback and analyser setup
  useEffect(() => {
    // Resume the audio context and set up the analyser
    if (audioContextRef.current && audioContextRef.current.state !== "closed") {
      audioContextRef.current.resume().then(() => {
        const audioContext = audioContextRef.current;
        const analyserNode = audioContext.createAnalyser();
        analyserNode.fftSize = 2048;
        // analyserNode.smoothingTimeConstant = 0.025;
        setAnalyser(analyserNode);

        // Connect the audio element to the analyserNode
        const source = audioContext.createMediaElementSource(audioRef.current);
        source.connect(analyserNode);
        analyserNode.connect(audioContext.destination);
      });
    }
  }, [selectedIndex, setAnalyser]);

  useEffect(() => {
    // Create a new AudioContext for audio processing
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    audioContextRef.current = audioContext;

    // Define a cleanup function for when the component unmounts or the effect is re-executed
    return () => {
      // Pause and clear the audio element if it exists
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.src = "";
      }

      // Close the audio context if it exists
      if (audioContext) {
        audioContext.close();
      }
    };
  }, []);

  const startTimer = () => {
    // Clear any timers already running
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      if (audioRef.current.ended) {
        onNext();
      } else {
        setTrackProgress(audioRef.current.currentTime);
      }
    }, [1000]);
  };

  // Toggle playback when isPlaying changes
  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
      startTimer();
    } else {
      audioRef.current.pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying]);

  // Handles cleanup and setup when changing tracks
  useEffect(() => {
    audioRef.current.pause();

    audioRef.current = new Audio(path);
    audioRef.current.volume = Number(volume);
    audioRef.current.crossOrigin = "anonymous";
    setTrackProgress(audioRef.current.currentTime);

    if (isReady.current && isPlaying) {
      audioRef.current.play();
      startTimer();
    } else {
      // Set the isReady ref as true for the next pass
      isReady.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [track]);

  useEffect(() => {
    // Pause and clean up on unmount
    return () => {
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    };
  }, []);

  const onScrub = (value) => {
    // Clear any timers already running
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = value;
    setTrackProgress(audioRef.current.currentTime);
  };

  const onScrubEnd = () => {
    // If not already playing, start
    if (!isPlaying) {
      setIsPlaying(true);
    }
    startTimer();
  };

  useEffect(() => {
    audioRef.current.volume = Number(volume);
  }, [volume]);

  function formatDuration(value) {
    const minute = Math.floor(value / 60);
    const secondLeft = value - minute * 60;
    return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
  }
  const mainIconColor = theme.palette.mode === "dark" ? "#fff" : "#000";
  const lightIconColor =
    theme.palette.mode === "dark" ? "rgba(255,255,255,0.4)" : "rgba(0,0,0,0.4)";

  return (
    <Widget>
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          justifyContent: "space-between",
        }}
      >
        <Stack direction="row" alignItems="center" sx={{ overflow: "hidden" }}>
          <CoverImage>
            <Image alt="track-artwork" src={"cover-sm.jpg"} />
          </CoverImage>
          <Box sx={{ ml: 1.5, minWidth: 0 }}>
            <Typography
              variant="caption"
              color="text.secondary"
              fontWeight={500}
            >
              {artist}
            </Typography>
            <Typography>
              <b>{name}</b>
            </Typography>
            <Typography noWrap letterSpacing={-0.25}>
              {album}
            </Typography>
          </Box>
        </Stack>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "start",
          }}
        >
          <Credits {...{ tabValue, isTabSelected, handleTabChange }} />
          <VisualizerButton {...{ showWaveform, setShowWaveform }} />
        </Box>
      </Box>
      <Slider
        aria-label="time-indicator"
        size="small"
        value={currentTime}
        min={0}
        max={Number(duration)}
        onMouseUp={onScrubEnd}
        onKeyUp={onScrubEnd}
        onChange={(x, value) => {
          onScrub(value);
        }}
        sx={{
          color: theme.palette.mode === "dark" ? "#fff" : "rgba(0,0,0,0.87)",
          height: 4,
          "& .MuiSlider-thumb": {
            width: 8,
            height: 8,
            transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
            "&:before": {
              boxShadow: "0 2px 12px 0 rgba(0,0,0,0.4)",
            },
            "&:hover, &.Mui-focusVisible": {
              boxShadow: `0px 0px 0px 8px ${
                theme.palette.mode === "dark"
                  ? "rgb(255 255 255 / 16%)"
                  : "rgb(0 0 0 / 16%)"
              }`,
            },
            "&.Mui-active": {
              width: 20,
              height: 20,
            },
          },
          "& .MuiSlider-rail": {
            opacity: 0.28,
          },
        }}
      />
      <Stack>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mt: -2,
          }}
        >
          <TinyText>{formatDuration(currentTime.toFixed(0))}</TinyText>
          <TinyText>
            -{formatDuration((track.duration - currentTime).toFixed(0))}
          </TinyText>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: -1,
          }}
        >
          <IconButton aria-label="previous song" onClick={onLast}>
            <FastRewindRounded fontSize="large" htmlColor={mainIconColor} />
          </IconButton>
          <IconButton
            aria-label={isPlaying ? "play" : "pause"}
            onClick={() => setIsPlaying(!isPlaying)}
          >
            {isPlaying ? (
              <PauseRounded
                sx={{ fontSize: "3rem" }}
                htmlColor={mainIconColor}
              />
            ) : (
              <PlayArrowRounded
                sx={{ fontSize: "3rem" }}
                htmlColor={mainIconColor}
              />
            )}
          </IconButton>
          <IconButton aria-label="next song" onClick={onNext}>
            <FastForwardRounded fontSize="large" htmlColor={mainIconColor} />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: -1,
          }}
        >
          <TrackListButton {...{ tabValue, isTabSelected, handleTabChange }} />
          <LyricsButton {...{ tabValue, isTabSelected, handleTabChange }} />
          <WallpaperButton {...{ tabValue, isTabSelected, handleTabChange }} />
        </Box>
      </Stack>
      <Stack
        spacing={2}
        direction="row"
        sx={{ mb: 1, px: 1 }}
        alignItems="center"
      >
        <VolumeDownRounded htmlColor={lightIconColor} />
        <Slider
          aria-label="Volume"
          defaultValue={100}
          onChange={(e) => {
            let v = e.target.value / 100;
            setVolume(v);
          }}
          sx={{
            color: theme.palette.mode === "dark" ? "#fff" : "rgba(0,0,0,0.87)",
            "& .MuiSlider-track": {
              border: "none",
            },
            "& .MuiSlider-thumb": {
              width: 24,
              height: 24,
              backgroundColor: "#fff",
              "&:before": {
                boxShadow: "0 4px 8px rgba(0,0,0,0.4)",
              },
              "&:hover, &.Mui-focusVisible, &.Mui-active": {
                boxShadow: "none",
              },
            },
          }}
        />
        <VolumeUpRounded htmlColor={lightIconColor} />
      </Stack>
      <Stack>
        {tabValue === "tracks" ? (
          children
        ) : tabValue === "lyrics" ? (
          <LyricsView isPlaying={isPlaying} time={currentTime} track={track} />
        ) : tabValue === "gallery" ? (
          <Gallery />
        ) : (
          <></>
        )}
      </Stack>
    </Widget>
  );
}

const VisualizerButton = ({ showWaveform, setShowWaveform }) => {
  const color = showWaveform ? "#f4cb0b" : `rgba(0, 0, 0,  0.2)`;
  const onClick = () => setShowWaveform((v) => !v);
  return (
    <Tooltip title="Toggle Visualizer" placement="left">
      <IconButton aria-label="toggle visualizer" onClick={onClick}>
        <AutoAwesomeRounded fontSize="small" htmlColor={color} />
      </IconButton>
    </Tooltip>
  );
};

const TrackListButton = ({ tabValue, isTabSelected, handleTabChange }) => {
  const isActive = isTabSelected("tracks");
  const color = `rgba(0, 0, 0, ${isActive ? 0.9 : 0.2})`;
  const onClick = () => handleTabChange("tracks");
  return (
    <Tooltip title="Tracks" placement="bottom">
      <IconButton aria-label="show tracks" onClick={onClick}>
        <QueueMusicRounded fontSize="small" htmlColor={color} />
      </IconButton>
    </Tooltip>
  );
};

const LyricsButton = ({ tabValue, isTabSelected, handleTabChange }) => {
  const isActive = isTabSelected("lyrics");
  const color = `rgba(0, 0, 0, ${isActive ? 0.9 : 0.2})`;
  const onClick = () => handleTabChange("lyrics");
  return (
    <Tooltip title="Lyrics" placement="bottom">
      <IconButton aria-label="show lyrics" onClick={onClick}>
        <Lyrics fontSize="small" htmlColor={color} />
      </IconButton>
    </Tooltip>
  );
};

const WallpaperButton = ({ tabValue, isTabSelected, handleTabChange }) => {
  const isActive = isTabSelected("gallery");
  const color = `rgba(0, 0, 0, ${isActive ? 0.9 : 0.2})`;

  const onClick = () => handleTabChange("gallery");
  return (
    <Tooltip title="Gallery" placement="bottom">
      <IconButton aria-label="show info" onClick={onClick}>
        <Wallpaper fontSize="small" htmlColor={color} />
      </IconButton>
    </Tooltip>
  );
};
