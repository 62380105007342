import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Tooltip,
  Dialog,
  IconButton,
  Toolbar,
  Stack,
  Divider,
  AppBar,
  Slide,
  Typography,
  Tab,
  Tabs,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { styled, useTheme } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Image from "./Image";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledTab = styled(Tab)({
  "&.Mui-selected": {
    color: "#6665ad",
  },
});

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    backgroundColor: "#6665ad",
  },
});

export default function FullScreenDialog() {
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);

  const lightIconColor =
    theme.palette.mode === "dark" ? "rgba(255,255,255,0.4)" : "rgba(0,0,0,0.4)";

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const styles = {
    h3: { fontWeight: "bold" },
    h5: { color: "gray" },
    img: { height: "200px", width: "200px" },
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Tooltip title="Credits" placement="left">
        <IconButton aria-label="show info" onClick={handleClickOpen}>
          <InfoIcon fontSize="small" htmlColor={lightIconColor} />
        </IconButton>
      </Tooltip>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{
            position: "relative",
            backgroundColor: "#6665ad",
            // backgroundImage: `url(./cover.webp)`,
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1, fontWeight: "bold" }}
              variant="h6"
              component="div"
            >
              Credits
            </Typography>
          </Toolbar>
        </AppBar>
        <Container maxWidth="lg" sx={{ mb: 3 }}>
          <Stack spacing={2} marginY={2}>
            <Image
              src="cover.webp"
              alt="Image 1"
              width="100%"
              ratio="21/9"
              borderRadius={4}
              sx={{ alignItems: "end", justifyContent: "end" }}
            />
            <Typography variant="h3" sx={styles.h3}>
              Just Say When
            </Typography>
            <Typography variant="h5" sx={styles.h5}>
              Parmesan Dreams
            </Typography>

            <Divider />
            <StyledTabs value={value} onChange={handleChange}>
              <StyledTab label="Credits" value={0} />
              <StyledTab label="About" value={1} />
            </StyledTabs>

            {value === 0 ? (
              <>
                <Stack direction="row" spacing={2}>
                  <Image
                    src={"assets/images/feedthemachine.webp"}
                    sx={{ width: 100, height: 100 }}
                    borderRadius={4}
                  />

                  <Typography variant="body1">
                    <b>1. Feed the Machine</b>
                    <Typography variant="body2">
                      <p>Music & lyrics by Will Smillie</p>
                      <p>Drums by Andrew Tachine</p>
                    </Typography>
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Image
                    src={"assets/images/dressedinblue.webp"}
                    sx={{ width: 100, height: 100 }}
                    borderRadius={4}
                  />

                  <Typography variant="body1">
                    <b>2. Dressed in Blue</b>
                    <Typography variant="body2">
                      <p>Music & lyrics by Will Smillie</p>
                      <p>Drums by Andrew Tachine</p>
                    </Typography>
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Image
                    src={"assets/images/makeumyhome.webp"}
                    sx={{ width: 100, height: 100 }}
                    borderRadius={4}
                  />

                  <Typography variant="body1">
                    <b>3. Make U My Home</b>
                    <Typography variant="body2">
                      <p>Music by Will Smillie</p>
                      <p>Lyrics by Will Smillie & Andrew Tachine</p>
                      <p>Drums by Andrew Tachine</p>
                    </Typography>
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Image
                    src={"assets/images/idontwannastayatthisparty.webp"}
                    sx={{ width: 100, height: 100 }}
                    borderRadius={4}
                  />

                  <Typography variant="body1">
                    <b>4. I Don't Wanna Stay at this Party Anymore</b>
                    <Typography variant="body2">
                      <p>Music & lyrics by Will Smillie</p>
                      <p>Drums by Andrew Tachine</p>
                    </Typography>
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <Image
                    src={"assets/images/somethingtorelyon.webp"}
                    sx={{ width: 100, height: 100 }}
                    borderRadius={4}
                  />

                  <Typography variant="body1">
                    <b>5. Something to Rely On</b>
                    <Typography variant="body2">
                      <p>Music & lyrics by Will Smillie</p>
                      <p>Drums by Andrew Tachine</p>
                    </Typography>
                  </Typography>
                </Stack>
                <Divider />
                <Typography variant="body1">
                  Mixed by Will Smillie
                  <br />
                  Mastered by Sam Snyder
                </Typography>
              </>
            ) : (
              <Stack alignItems="center" justifyContent="center">
                <Typography sx={{ fontFamily: "Georgia, serif" }} maxWidth="md">
                  I tend to obsess, and while I rather enjoy the process, I find
                  releases to be bittersweet. I’m sure you’ve heard the saying
                  about the journey and not the destination. It’s the same way I
                  feel about hiking and games; I’m not one who necessarily
                  enjoys the end, much less winning. It honestly leaves me
                  feeling kinda blue. “Now what?” I think, “Onto the next I
                  guess”, which is always easier said than done. I frequently
                  struggle to break the inertia. While there is almost always a
                  project or two on the back burner, actually getting invested,
                  much less excited and invigorated, is a whole other endeavor.
                  <br />
                  <br />
                  Just Say When is a reconciliation if you will. Parmesan
                  Dreams’ self titled EP opened a door for opportunity and
                  exploration. It felt focused, but broad; dialed in, but opened
                  up; sad, but funky. This time around, we took a slightly
                  different approach while striving for the same fundamentals.
                  It is our artistic attempt to explore new territory sonically,
                  lyrically, and artistically, while sticking to the crux of
                  whatever it is which we never really set out to do. It is
                  merely, yet another amalgamation of thoughts & feelings,
                  moments & sentiments, hopes & desires, curated and packaged
                  into what I can only hope is something which resonates with
                  you!
                  <br />
                  <br />
                  Traversed on this release are themes of late-stage capitalism,
                  personal growth, struggles with futures unknown, isolation,
                  and mental health, all threaded together and carried along by
                  a series of tight rhythms & grooves, catchy melodic hooks,
                  honest lyrics, and rich layers of harmonic playfulness. Built
                  on a foundation of repetition of patterns, and subvertion of
                  expectations, Parmesan Dreams aim to teleport you to surreal
                  worlds dreamt up, sculpted, and curated by the group. Each
                  song strives to pack a punch, delivering a charismatic world
                  which places you center stage where the lyrics and music
                  strive to engulf you wholeheartedly.
                  <br />
                  <br />
                  Feed the Machine opens the record, where mechanical rhythms
                  and asymmetrical guitar arrangements, resembling archaic
                  assembly lines, depict dystopian futures perpetrated by Big
                  Tech, ruled by AI, and fueled by mass surveillance. Where a
                  new age digital of feudalism reigns supreme. It aims to
                  captures an essence of futures fraught with class conflict and
                  a tinge of existential dread.
                  <br />
                  <br />
                  Dressed in Blue renders idyllic scenes of an Ozian journey
                  where attempts to stay on track carry you through a
                  voyeuristic experience which emanates the sentiment that while
                  Rome wasn’t built in a day, its vision can be seen shimmering
                  in the distance. The sparkling synths and titillating delays
                  stimulate a sense of hopefulness about the future, while the
                  lyrics contemplate a more melancholy side of the arrangement,
                  alternating between “in the morning I’ll be fine”, “in the
                  morning you’ll be fine”.
                  <br /> <br />
                  Make U My Home explores the, at times, asynchronous &
                  syncopated natures of a relationship, present both
                  instrumentally and lyrically. A story depicting tales of
                  distance and struggles with communication. With dynamic
                  melodies and a rich arrangement containing a catchy
                  post-chorus faux-horn synth section, along with a stuttering
                  time change chased by a tender unison guitar/synth solo to
                  send it off.
                  <br /> <br />
                  I Don't Wanna Stay at This Party Anymore provides a moment of
                  relief, a breath of fresh air which recounts feelings of
                  loneliness; contrasting company with dissociated states of
                  isolation and discomfort, in a stark juxtaposition paired with
                  bouncy bossa beats and poignant time changes, a subtle nod to
                  the emotional whiplash felt by introverts after a long night
                  out, with a washed out, psychedelic, outro reminiscent of the
                  post-party crash which often follows.
                  <br /> <br />
                  The record wraps with Something to Rely On, a track which ties
                  a bow on the set, sitting somewhere at the crossroads of a
                  psychedelic-country and disco-pop/rock track, exploring
                  concepts of mental health and dependency; the feelings of
                  floating through space, grasping for something, anything, to
                  hold onto to orient oneself. With a catchy hook to leave you
                  wanting more, and a larger than life bridge to send it home.
                  <br /> <br />
                  Just Say When is a collection of songs which originated
                  between 2018 & 2022, written and recorded by Will Smillie
                  (vocals, guitar, bass, keys), in collaboration with Andrew
                  Tachine (drums, percussion, keys), and mastered by Sam Snyder.
                  <br /> <br />
                  With much love & appreciation,
                  <br /> <br />
                  Will Smillie
                  <br /> <br />
                  July 26, 2023
                  <br /> <br />
                  Parmesan Dreams - Just Say When
                </Typography>
              </Stack>
            )}
          </Stack>
        </Container>
      </Dialog>
    </div>
  );
}
